import { Tag, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../util/constants";

function ModelCard({ data }) {
  const startTime = new Date(data?.startTime).getTime();
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const timeSinceStart = Math.floor((now - startTime) / 1000); // Convert to seconds
      setElapsedTime(timeSinceStart);
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup function to stop timer on unmount
  }, [startTime]); // Dependency array ensures effect runs only on startTime change

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const dateTimeFormat = (date) => {
    const dateTimeString = date;
    const dateObject = new Date(dateTimeString);

    // Choose desired format (you can customize this)
    const shortDateFormat = "M/d/yyyy : h:mm:ss"; // Example: 3/22/2024

    return dateObject.toLocaleDateString("en-US", { format: shortDateFormat });
  };

  const get_status_time = (startTime, endTime) => {
    if (startTime && endTime) {
      const parsedStartTime = new Date(startTime);
      const parsedEndTime = new Date(endTime);

      // Ensure parsed dates are valid before proceeding
      if (isNaN(parsedStartTime.getTime()) || isNaN(parsedEndTime.getTime())) {
        return "Invalid time format";
      }

      const differenceInMilliseconds =
        parsedEndTime.getTime() - parsedStartTime.getTime();
      const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor(
        (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor(
        (differenceInMilliseconds % (1000 * 60)) / 1000
      );

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return "Missing start or end time";
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <Row className="card-list" gutter={[16, 16]}>
          <Col
            style={{
              display: "grid",
              position: "relative",
              margin: "0 0 20px 0",
            }}
            xs={24}
            sm={24}
            md={24}
            lg={3}
            xl={3}
          >
            <Tag
              style={{
                textAlign: "center",
                textTransform: "uppercase",
                position: "relative",
                top: "-30px",
                left: "-30px",
                borderRadius: "13px 0",
                width: "125px",
              }}
              color={
                data.status === "Initiated"
                  ? "rgb(31 59 179 / 90%)"
                  : data.status === "Completed"
                  ? "rgb(56 158 13 / 90%)"
                  : "rgb(212 177 6 / 90%)"
              }
            >
              {data.status}
            </Tag>
            <img
              className="list-logo-img"
              src={data?.modelData?.logo}
              alt={data?.modelData?.logo}
              title={data?.modelData?.logo}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={18} xl={18}>
            <Row gutter={[16, 16]}>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <span className="list-details">
                  <label className="label-list">Model Name</label>
                  <strong className="label-value">{data.projectName}</strong>
                </span>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <span className="list-details">
                  <label className="label-list">Model Type</label>
                  <strong className="label-value">{data.machineType}</strong>
                </span>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <span className="list-details">
                  <label className="label-list">Cloud</label>
                  <strong className="label-value">{data.cloudName}</strong>
                </span>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <span className="list-details">
                  <label className="label-list">Region</label>
                  <strong className="label-value">{data.region}</strong>
                </span>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <span className="list-details">
                  <label className="label-list">App type</label>
                  <strong className="label-value">{data.appType}</strong>
                </span>
              </Col>
            </Row>
          </Col>

          <Col
            style={{ display: "inline-grid", gap: "10px" }}
            xs={24}
            sm={24}
            md={24}
            lg={3}
            xl={3}
          >
            <Link
              className="view-button"
              size="small"
              type="button"
              to={`${routes.MODEL_DETAILS}/${data.id}`}
            >
              View Details
            </Link>

            {data.status === "Initiated" ? (
              data?.startTime ? (
                <div style={{ display: "contents", textAlign: "center" }}>
                  <label className="label-list">Running time</label>
                  <strong
                    className="label-value"
                    style={{ marginTop: "-10px" }}
                  >
                    {formatTime(elapsedTime)}
                  </strong>
                </div>
              ) : null
            ) : data.status === "Completed" ? (
              <div style={{ display: "contents", textAlign: "center" }}>
                <label className="label-list">Time taken</label>
                <strong className="label-value" style={{ marginTop: "-10px" }}>
                  {get_status_time(data.startTime, data.endTime)}
                </strong>
              </div>
            ) : (
              <div style={{ display: "contents", textAlign: "center" }}>
                <label className="label-list">Created at</label>
                <strong className="label-value" style={{ marginTop: "-10px" }}>
                  {dateTimeFormat(data.creationTime)}
                </strong>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ModelCard;
