import React from "react";
import { Layout, Dropdown, Badge, Button, Avatar } from "antd";
import SiderDrawerPhone from "../dashboard/SiderDrawerPhone";
import { useMsal } from "@azure/msal-react";
import iconSvg from "../../assests/img/avatar1.gif";
import { Link } from "react-router-dom";
import avatarNotif from "../../assests/img/Floating Robot.jpg";
const { Header } = Layout;

export default function HeaderAdmin(props) {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.logoutRedirect();
  };

  const items = [
    {
      label: (
        <span onClick={handleLoginRedirect}>
          <svg
            width={20}
            height={20}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
            />
          </svg>

          <span
            className="ms-1"
            style={{ paddingLeft: "5px", fontSize: "14px" }}
          >
            Sign Out
          </span>
        </span>
      ),
      key: "1",
    },
  ];

  return (
    <Header
      className="header-admin site-layout-background"
      style={{ padding: 0 }}
    >
      <SiderDrawerPhone user={props.user} />

      <ul style={{ display: "inline-flex", float: "right" }}>
        {" "}
        <div className="search-container">
          <div className="search-box">
            <input
              type="text"
              className="search-box-input"
              placeholder="What are you looking for ?"
            />
            <button className="search-box-btn">
              <svg
                width={20}
                height={20}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <span className="header-nav">
          <ul className="navbar-links ">
            <Badge dot key="orange" color="orange">
              <li className="navbar-dropdown">
                <svg
                  role="button"
                  width={20}
                  height={20}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                  />
                </svg>

                <div className="dropdown">
                  <div
                    style={{
                      marginBottom: 0,
                      padding: "15px 25px",
                      cursor: "pointer",
                      borderBottom: "1px solid #dee2e6",
                      lineHeight: 1,
                      display: "flex",
                      placeContent: "space-between",
                    }}
                  >
                    <p className="mb-0 font-weight-medium float-start mt-1">
                      You have 2 unread items
                    </p>
                    <Button style={{ borderRaduis: "25px" }} size="small">
                      View all
                    </Button>
                  </div>

                  <Link
                    // key={index}
                    to="/"
                  >
                    <div className="preview-thumbnail">
                      <Avatar
                        size={40}
                        src={<img src={avatarNotif} alt="avatar" />}
                      />
                    </div>
                    <div className="preview-item-content flex-grow py-2">
                      <p className="preview-subject ellipsis fw-medium text-dark">
                        Code refactor assistant
                      </p>
                      <p className="ellipsis-status fw-light small-text mb-0">
                        Model training completed
                      </p>
                    </div>
                  </Link>
                  <Link
                    // key={index}
                    to="/"
                  >
                    <div className="preview-thumbnail">
                      <Avatar
                        size={40}
                        src={<img src={avatarNotif} alt="avatar" />}
                      />
                    </div>
                    <div className="preview-item-content flex-grow py-2">
                      <p className="preview-subject ellipsis fw-medium text-dark">
                        Generate Images
                      </p>
                      <p className="ellipsis-status fw-light small-text mb-0">
                        Model training stopped
                      </p>
                    </div>
                  </Link>
                </div>
              </li>
            </Badge>
          </ul>
        </span>
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <div
            className="avatar-user-details"
            onClick={(e) => e.preventDefault()}
          >
            <div className="avatar-box">
              <span className="avatar-name">
                <strong>{props.user ? props.user.name : ""}</strong>
              </span>
              <div className="avatar-img">
                <Avatar
                  style={{ backgroundColor: "#d6d6d6" }}
                  size={40}
                  // icon={<UserOutlined />}
                >
                  <img
                    // style={{ margin: "-10px 0 0 -10px" }}
                    width={40}
                    height={35}
                    src={iconSvg}
                    alt="Avatar"
                  />
                </Avatar>
              </div>
            </div>
          </div>
        </Dropdown>
      </ul>
    </Header>
  );
}
