import { createContext, useContext } from "react";

export const ModelContext = createContext({
  models: [],
  model: {},
  addModel: (model) => {},
  fetchModels: () => {},
  fetchModelById: (id) => {},
  trainModel: (id) => {},
});

export const useModel = () => {
  return useContext(ModelContext);
};

export const ModelProvider = ModelContext.Provider;
