import axios from "axios";

class ModelService {
  getAllModels = async () => {
    const url = "http://10.26.9.57:8000/model-details";
    const resp = await axios.get(url);
    return resp.data;
  };
  getModelById = async (id) => {
    const params = {
      id,
    };
    const url = `http://10.26.9.57:8000/model-detail`;
    const resp = await axios.get(url, { params });
    return resp.data;
  };

  trainModel = async (id) => {
    const params = {
      id,
    };
    const url = `http://10.26.9.57:8000/launch-ec2-instance`;
    const resp = await axios.get(url, { params });
    return resp.data;
  };

  buildModel = async (model) => {
    const url = "http://10.26.9.57:8000/create-model";
    const resp = await axios.post(url, model);
    return resp.data;
  };
}
const modelServiceInstance = new ModelService();

export default modelServiceInstance;
