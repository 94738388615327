import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../../../components/BreadCrumbs";
import ModelDetailsCard from "./ModelDetailsCard";
import { ModelProvider } from "../../../context/model";
import modelServiceInstance from "../../../services/ModelService";

export default function ModelDetails(props) {
  const [model, setModel] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchModelById = (id) => {
    modelServiceInstance
      .getModelById(id)
      .then((resp) => {
        setModel(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const trainModel = (id) => {
    modelServiceInstance
      .trainModel(id)
      .then((resp) => {
        setModel(resp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="container">
        <Row className="title-row">
          <Col xs={12} sm={12} md={20} lg={20} xl={20}>
            <h2 className="page-title">Model Details</h2>
            <Breadcrumb location={props.location} />
          </Col>
        </Row>
        <Row className="statistics-card">
          <Col span={24}>
            <ModelProvider value={{ model, fetchModelById, trainModel }}>
              <ModelDetailsCard model={model} />
            </ModelProvider>
          </Col>
        </Row>
      </div>
    </>
  );
}
