export const routes = {
  HOME: "/",
  ADMIN_DASHBOARD: "/dashboard",
  MODEL: "/models",
  MODEL_DETAILS: "/models",
};
export const brand = {
  NAME: "Model-Training",
};

export const backend_base_url =
  "https://js2x8ojx2i.execute-api.us-east-1.amazonaws.com/dev";
