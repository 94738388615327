import React from "react";
import { useModel } from "../../context/model";
import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd";
import { ServerError } from "@azure/msal-browser";
import { InboxOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import axios from "axios";
const { Option } = Select;
const { Dragger } = Upload;

function CreateModel(props) {
  const { addModel } = useModel();
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalList, setModalList] = useState([]);
  const [fields, setFields] = useState({
    instanceType: "",
    cloudName: "",
    project_name: "",
    region: "",
    appType: "",
    modelName: "",
    dataset: "",
  });
  const getModalList = async () => {
    console.log("called api");
    const url = "http://10.26.9.57:8000/model-master-data";
    const res = await axios.get(url);
    console.log("res", res.data);
    setModalList(res.data);
  };
  useEffect(() => {
    getModalList();
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    console.log("fields", fields);

    let formData = new FormData();

    for (let key in fields) {
      if (fields.hasOwnProperty(key)) {
        formData.append(key, fields[key]);
      }
    }
    addModel(formData);

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    console.log("Success:", fields);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFields({ ...fields, [name]: value });
  };

  const propsForFileUpload = {
    name: "file",
    multiple: false,
    maxCount: 1,

    // accept: "application/pdf",
    // disabled: true,
    beforeUpload: (file) => {
      // callBeforeUpload(file);
      return false; // Prevent automatic file upload
    },
    onRemove: (file) => {
      // const filterList = files.filter(
      //   (doc) => doc.uid !== file.originFileObj.uid
      // );
      // setFiles(filterList);
    },
    onChange(info) {
      console.log("onfo", info);
      setFields({ ...fields, dataset: info.file });
      // if (info.file.status === "removed") {
      //   message.info("File removed");
      // } else {
      //   setFiles([...files, info.file]);
      //   message.success("File uploaded successfully");
      // }
    },
  };

  // const showModal = (e) => {
  //   e.preventDefault();
  //   const model = { name: "test" };

  // };
  return (
    <>
      <button onClick={showModal} className="float-end create-btn">
        <span className="text">
          <svg
            width={15}
            height={15}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          Create
        </span>
      </button>
      <Modal
        title="Create Modal"
        footer={[
          <Button
            key="Create"
            type="primary"
            onClick={handleOk}
            style={{ marginTop: "20px" }}
          >
            Create
          </Button>,
        ]}
        open={isModalOpen}
        width={1000}
        onCancel={handleCancel}
      >
        <Form
          name="Create Modal"
          className="Create Modal"
          form={form}
          // onKeyDown={onKeyDownSubmit}
          onFinish={onFinish}
          initialValues={{
            remember: true,
          }}
        >
          <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Enter Instance Type">
                <Form.Item
                  name="instanceType"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Instance Type!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Enter Instance Type"
                    name="instanceType"
                    value={fields.instanceType}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Enter Cloud Name">
                <Form.Item
                  name="cloudName"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Cloud Name!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Enter Cloud Name"
                    name="cloudName"
                    value={fields.cloudName}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Project Name">
                <Form.Item
                  name="project_name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Project Name!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    name="project_name"
                    placeholder="Enter Project Name"
                    value={fields.project_name}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="Region">
                <Form.Item
                  name="region"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Region Name!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    name="region"
                    value={fields.region}
                    placeholder="Enter Region Name"
                    onChange={handleChange}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Tooltip title="App Type">
                <Form.Item
                  name="appType"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter App Type!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    name="appType"
                    placeholder="Enter App Type"
                    value={fields.appType}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
              <Form.Item
                name="modelName"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please select modal name!",
                  },
                ]}
              >
                <Tooltip title="Select Modal Name">
                  <Select
                    placeholder="Select Modal"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setFields({ ...fields, modelName: e });
                    }}
                    // defaultValue={fields.status}
                    // disabled
                  >
                    {modalList?.map((item) => {
                      return <Option value={item.id}>{item.modelName}</Option>;
                    })}
                  </Select>
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Dragger {...propsForFileUpload}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click / drag and drop to upload files here
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload files of extention .pdf
                </p>
              </Dragger>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default CreateModel;
