import React, { useState } from "react";
import { Layout } from "antd";
import HeaderAdmin from "../layouts/HeaderAdmin";
import SiderMenu from "./SiderMenu";
import { routes } from "../../util/constants";
import { useLocation, useParams } from "react-router-dom";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { msalConfig } from "../../authConfig";
import { InteractionType } from "@azure/msal-browser";
import Dashboard from "../../pages/dashboard";
import Model from "../../pages/model/Model";
import ModelDetails from "../../pages/model/model-details/ModelDetails";

const { Sider, Content, Footer } = Layout;

export default function AdminDashboard(props) {
  const { id } = useParams();
  const { instance } = useMsal();
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const { pathname } = props.location;

  function renderPathName(pathname) {
    switch (pathname) {
      case routes.MODEL:
        return <Model location={location} />;
      case `${routes.MODEL_DETAILS}/${id}`:
        return <ModelDetails location={location} />;

      default:
        return <Dashboard location={location} />;
    }
  }
  const authRequest = {
    ...msalConfig.auth.scopes,
  };
  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <Layout className="admin-dash ">
          <Sider
            width={260}
            breakpoint="sm"
            collapsedWidth={"100"}
            theme="light"
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <SiderMenu
              toggle={toggle}
              history={props.history}
              user={props.user}
              collapsed={collapsed}
            ></SiderMenu>
          </Sider>
          <Layout className="site-layout">
            <HeaderAdmin
              toggle={toggle}
              collapsed={collapsed}
              user={instance.getActiveAccount()}
              signOut={props.signOut}
            />

            <Content
              style={{
                margin: "30px 20px",
                minHeight: "100vh",
              }}
            >
              {renderPathName(pathname)}
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              ©{new Date().getFullYear()} YASH Technologies. All Rights
              Reserved.
            </Footer>
          </Layout>
        </Layout>
      </MsalAuthenticationTemplate>
    </>
  );
}
