import React, { useEffect, useState } from "react";
import { Button, Col, Row, Tag } from "antd";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { useModel } from "../../../context/model";

function ModelDetailsCard({ model }) {
  const { fetchModelById, trainModel } = useModel();
  const { id } = useParams();
  const startTime = new Date(model?.startTime).getTime();
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    fetchModelById(id);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const timeSinceStart = Math.floor((now - startTime) / 1000); // Convert to seconds
      setElapsedTime(timeSinceStart);
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup function to stop timer on unmount
  }, [startTime]); // Dependency array ensures effect runs only on startTime change

  const handleTrainModel = (e, id) => {
    trainModel(id);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const dateTimeFormat = (date) => {
    const dateTimeString = date;
    const dateObject = new Date(dateTimeString);

    // Choose desired format (you can customize this)
    const shortDateFormat = "M/d/yyyy"; // Example: 3/22/2024

    return dateObject.toLocaleDateString("en-US", { format: shortDateFormat });
  };

  const get_status_time = (startTime, endTime) => {
    if (startTime && endTime) {
      const parsedStartTime = new Date(startTime);
      const parsedEndTime = new Date(endTime);

      // Ensure parsed dates are valid before proceeding
      if (isNaN(parsedStartTime.getTime()) || isNaN(parsedEndTime.getTime())) {
        return "Invalid time format";
      }

      const differenceInMilliseconds =
        parsedEndTime.getTime() - parsedStartTime.getTime();
      const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor(
        (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor(
        (differenceInMilliseconds % (1000 * 60)) / 1000
      );

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    } else {
      return "Missing start or end time";
    }
  };

  return (
    <>
      {Object.keys(model).length !== 0 ? (
        <div className="card">
          <div className="card-body">
            <div style={{ minHeight: 90 }}>
              <Row
                style={{ placeContent: "end", marginBottom: 10 }}
                gutter={[16, 16]}
              >
                <Col
                  style={{
                    display: "grid",
                    position: "relative",
                    margin: "0 auto 20px 0",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Tag
                    style={{
                      textAlign: "center",
                      textTransform: "uppercase",
                      position: "relative",
                      top: "-30px",
                      left: "-30px",
                      borderRadius: "13px 0",
                      width: "125px",
                    }}
                    color={
                      model.status === "Initiated"
                        ? "rgb(31 59 179 / 90%)"
                        : model.status === "Completed"
                        ? "rgb(56 158 13 / 90%)"
                        : "rgb(212 177 6 / 90%)"
                    }
                  >
                    {model.status}
                  </Tag>
                  <img
                    className="list-logo-img"
                    style={{ top: "-15px" }}
                    src={model.modelData.logo}
                    alt={model.modelData.logo}
                    title={model.modelData.logo}
                  />
                </Col>

                <span>
                  <Button
                    className="update-button me-3"
                    type="primary"
                    // shape="circle"
                    size="small"
                    icon={
                      <svg
                        width={15}
                        height={15}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                        />
                      </svg>
                    }
                  >
                    Update
                  </Button>

                  <Button
                    className="delete-button"
                    type="primary"
                    // shape="circle"
                    size="small"
                    icon={
                      <svg
                        width={15}
                        height={15}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                    }
                  >
                    Delete
                  </Button>
                </span>
              </Row>

              <Row gutter={[16, 16]}>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <span className="list-details">
                    <label className="label-list">Model Name</label>
                    <strong className="label-value">{model.projectName}</strong>
                  </span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <span className="list-details">
                    <label className="label-list">Model Type</label>
                    <strong className="label-value">{model.machineType}</strong>
                  </span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <span className="list-details">
                    <label className="label-list">Cloud</label>
                    <strong className="label-value">{model.cloudName}</strong>
                  </span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <span className="list-details">
                    <label className="label-list">Region</label>
                    <strong className="label-value">{model.region}</strong>
                  </span>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <span className="list-details">
                    <label className="label-list">App type</label>
                    <strong className="label-value">{model.appType}</strong>
                  </span>
                </Col>
              </Row>

              <hr style={{ opacity: "0.15" }} />

              <Row gutter={[16, 16]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div>
                    {model.status === "Initiated" ? (
                      model?.startTime ? (
                        <p style={{ color: "#16A085" }}>
                          Model running from{" "}
                          <span>{formatTime(elapsedTime)}</span>
                        </p>
                      ) : null
                    ) : model.status === "Completed" ? (
                      <p style={{ color: "#16A085" }}>
                        Model training completed in{" "}
                        <span>
                          {get_status_time(model.startTime, model.endTime)}
                        </span>
                      </p>
                    ) : (
                      <p style={{ color: "#16A085" }}>
                        Model created at{" "}
                        <span>{dateTimeFormat(model.creationTime)}</span>
                      </p>
                    )}
                  </div>
                </Col>
                <Col
                  style={{ textAlign: "end" }}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  {model.status === "Initiated" ? (
                    <Button className="stop-button" size="small" type="primary">
                      Stop
                    </Button>
                  ) : model.status === "Completed" ? (
                    <Button
                      className="restart-button"
                      size="small"
                      type="primary"
                    >
                      Restart
                    </Button>
                  ) : (
                    <Button
                      className="start-button"
                      size="small"
                      type="primary"
                      onClick={(e) => {
                        handleTrainModel(e, model.id);
                      }}
                    >
                      Train
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default ModelDetailsCard;
