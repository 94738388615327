import React from "react";
import { Col, Row } from "antd";
import CountCards from "./CountCards";

export default function Dashboard(props) {
  return (
    <>
      <div className="container">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <h2 className="page-title">Dashboard</h2>
          </Col>
        </Row>
        <CountCards />
        <Row gutter={[32, 8]} className="statistics-card mt-3">
          <Col xs={24} sm={24} md={12} lg={15} xl={15}>
            <div className="card">
              <div className="card-body">Table / Stats</div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={9} xl={9}>
            <div className="card">
              <div className="card-body">Stats / Donut</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
