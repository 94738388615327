import React, { useState } from "react";
import { Col, Row } from "antd";
import Breadcrumb from "../../components/BreadCrumbs";
import CreateModel from "./CreateModel";
import ModelLists from "./ModelLists";
import { ModelProvider } from "../../context/model";
import modelServiceInstance from "../../services/ModelService";
import { openNotificationBar } from "../../components/NotificationBar";

export default function Model(props) {
  const [models, setModels] = useState([]);

  const addModel = (model) => {
    modelServiceInstance
      .buildModel(model)
      .then((resp) => {
        console.log("@@@resp", resp);
        setModels((prev) => [resp, ...prev]);
        openNotificationBar({
          type: "success",
          title: "Success",
          description: `Model ${resp.name} created successfully`,
        });
      })
      .catch((err) => console.log(err));
  };

  const fetchModels = () => {
    modelServiceInstance
      .getAllModels()
      .then((resp) => {
        setModels(resp);
      })
      .catch((err) => console.log(err));
  };

  return (
    <ModelProvider value={{ models, addModel, fetchModels }}>
      <div className="container">
        <Row className="title-row">
          <Col xs={12} sm={12} md={20} lg={20} xl={20}>
            <h2 className="page-title">Models</h2>
            <Breadcrumb location={props.location} />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <CreateModel />
          </Col>
        </Row>
        <Row className="statistics-card">
          <Col span={24}>
            <ModelLists models={models} />
          </Col>
        </Row>
      </div>
    </ModelProvider>
  );
}
