import React from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { brand, routes } from "../../util/constants";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

export default function SiderMenu(props) {
  const brandArr = brand.NAME.split("-");
  return (
    <div
      className={
        props.collapsed
          ? "sider-menu collapse-menu-position"
          : "sider-menu menu-position"
      }
    >
      <div className="side-header">
        <div className="brand-name">
          <div className="brand-logo ms-3">
            {/**<img className="logo" src={logo} alt="Logo" /> */}
            {React.createElement(
              props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: props.toggle,
              }
            )}

            <span
              className="app-name mt-1 "
              style={{
                fontSize: "1.5rem",
                color: "#000000",
                fontWeight: 700,
              }}
              mode="full"
            >
              {brandArr[0]}
              <span
                style={{
                  fontSize: "1.5rem",
                  color: "#1F3BB3",
                  fontWeight: 700,
                }}
              >
                {brandArr[1]}
              </span>
            </span>
          </div>
        </div>
      </div>

      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
      >
        <Menu.Item
          key={routes.ADMIN_DASHBOARD}
          icon={
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          }
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.ADMIN_DASHBOARD}
          >
            Dashboard
          </NavLink>
        </Menu.Item>
        <p className="menu-subhead" mode="full">
          OPERATIONS
        </p>
        <Menu.Item
          key={routes.MODEL}
          icon={
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
              />
            </svg>
          }
        >
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.MODEL}
          >
            Models
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
}
