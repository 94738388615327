import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import NavRoutes from "./NavRoutes";
import { brand } from "./util/constants";

function App({ instance }) {
  useEffect(() => {
    document.title = brand.NAME;
    document.body.style.zoom = "85%";
  }, []);
  return (
    <MsalProvider instance={instance}>
      <BrowserRouter>
        <NavRoutes />
      </BrowserRouter>
    </MsalProvider>
  );
}

export default App;
