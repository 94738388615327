import React, { useEffect, useState } from "react";
import ModelCard from "./ModelCard";
import Spinner from "../../components/Spinner";
import { useModel } from "../../context/model";
import { Empty } from "antd";

function ModelLists({ models }) {
  const [loading, setLoading] = useState(true);
  const { fetchModels } = useModel();

  useEffect(() => {
    fetchModels();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Spinner />}
      {models.length ? (
        models.map((model) => <ModelCard key={model.id} data={model} />)
      ) : (
        <Empty />
      )}
    </>
  );
}

export default ModelLists;
