import React, { useState } from "react";
import { Menu, Drawer } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { brand, routes } from "../../util/constants";

export default function SiderDrawerPhone() {
  const [visible, setVisible] = useState(false);
  const [placement] = useState("left");
  const brandArr = brand.NAME.split("-");

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <span>
      <MenuUnfoldOutlined onClick={showDrawer} className="trigger-phone" />
      <Drawer
        placement={placement}
        closable={false}
        onClose={onClose}
        open={visible}
        key={placement}
      >
        <div className="side-header-small">
          <div className="side-header">
            <div className="brand-name" style={{ margin: "0 0 0 10px" }}>
              <div className="brand-logo ms-3">
                <span
                  className="app-name mt-1 "
                  style={{
                    fontSize: "1.5rem",
                    color: "#000000",
                    fontWeight: 700,
                  }}
                  mode="full"
                >
                  {brandArr[0]}
                  <span
                    style={{
                      fontSize: "1.5rem",
                      color: "#1F3BB3",
                      fontWeight: 700,
                    }}
                  >
                    {brandArr[1]}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[window.location.pathname]}
        >
          <Menu.Item
            key={routes.ADMIN_DASHBOARD}
            icon={
              <svg
                width={20}
                height={20}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            }
          >
            <NavLink
              className="sider-links text-decoration-none"
              to={routes.ADMIN_DASHBOARD}
            >
              Dashboard
            </NavLink>
          </Menu.Item>
          <p className="menu-subhead" mode="full">
            OPERATIONS
          </p>
        </Menu>
      </Drawer>
    </span>
  );
}
