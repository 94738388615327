import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function Spinner(props) {
  return (
    <div>
      <Spin
        className="spinner"
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
      <p className="spinner mt-2">Loading...</p>
    </div>
  );
}

export default Spinner;
